


































import { ILearnPack } from '@/models';
import { learnPackSearvice } from '@/services';
import { defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { Pagination } from '@/components';
import { normalizeParams } from '@/utils';
import { LearnPackList } from './components';

export default defineComponent({
  name: 'LearnPackIndex',
  components: {
    Pagination,
    LearnPackList,
  },
  setup(_, { root }) {
    const isLoading = ref(false);

    const learnPackList = ref<ILearnPack[]>([]);

    const pagination = reactive({
      page: 1,
      size: 20,
      totalCount: 0,
    });

    async function fetchData() {
      if (isLoading.value) return;

      isLoading.value = true;
      try {
        const { content, totalElements } = await learnPackSearvice.getLearnPackList({
          page: pagination.page,
          size: pagination.size,
        });

        learnPackList.value = content;
        pagination.totalCount = totalElements;
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(error);
        }
      } finally {
        isLoading.value = false;
      }
    }

    function updateQueryString() {
      root.$router
        .push({
          query: {
            ...normalizeParams({
              ...root.$route.query,
              page: pagination.page,
              size: pagination.size,
            }),
          },
        })
        .catch(() => false);
    }

    function onChangePage() {
      window.scrollTo({ top: 0 });

      updateQueryString();
    }

    watch(
      () => root.$route,
      () => {
        fetchData();
      },
      {
        deep: true,
        immediate: true,
      },
    );

    onMounted(() => {
      fetchData();
    });

    return {
      isLoading,
      pagination,

      learnPackList,

      onChangePage,
    };
  },
});
